import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";

function acercade() {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Qué es Boone´s",
          metaDescription:
            "Ahora que ya sabes qué es Boone’s, recuerda que no importa el momento, la compañía, ni cómo lo hagas",
        }}
      />
      <header className="mb-5 md:mb-10">
        <StaticImage
          src="../assets/images/sabores/queesboones/banner-queesboones.png"
          alt="que es boones"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      <main className="container">
        <div className="mb-10">
          <StaticImage
            src="../assets/images/sabores/queesboones/info-queesboones-desktop.png"
            alt="Que Es Boones"
            placeholder="tracedSVG"
          />
        </div>
        <div className="m-4 pb-2 text-center">
          <h5 className="uppercase">como se</h5>
          <h1 className="uppercase text-sunpeakpeach">Toma</h1>
        </div>
        <div className="flex flex-col justify-center gap-5 md:flex-row">
          <div>
            <p className="max-w-prose">
              Ahora que ya sabes qué es Boone’s, recuerda que no importa el
              momento, la compañía, ni cómo lo hagas.
            </p>
            <p className="max-w-prose">
              Gracias al sabor de Boone’s, puedes preparar y crear un sinfín de
              recetas para poner tu creatividad al máximo nivel o si lo tuyo son
              los planes más sencillos, simplemente puedes servir y listo. Si
              estás en busca de ideas, aquí te dejamos algunas:
            </p>
          </div>
          <div>
            <ul className="list-inside list-disc marker:text-sunpeakpeach">
              <li>
                Con fruta: para un toque mucho más dulce, puedes agregar tus
                frutas favoritas.
              </li>
              <li>
                En coctel: con un par de ingredientes extras puedes preparar un
                delicioso coctel.
              </li>
              <li>
                Solito: con una temperatura entre 7° y 10° para resaltar aún más
                su sabor.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-10 grid grid-flow-col grid-cols-3 justify-center gap-2 rounded md:grid-cols-5">
          <StaticImage
            src="../assets/images/sabores/queesboones/img-toma-1.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-toma-2.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-toma-3.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-toma-4.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-toma-5.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </div>
        <div className="m-4 pb-2 text-center">
          <h5 className="uppercase">como se</h5>
          <h1 className="uppercase text-deliciousapple">prepara</h1>
        </div>
        <div className="flex flex-col justify-center gap-5 md:flex-row">
          <div className="md:w-full">
            <p className="max-w-prose">
              ¿Antojo de algo saladito, suave, dulce o frutal? Si estás en busca
              de acompañar o resaltar los sabores de tus platillos favoritos,
              esta mini guía te ayudará a encontrar el match perfecto para ese
              postre o comida favorita.
            </p>
            <p className="max-w-prose">
              Gracias al sabor de Boone’s, puedes preparar y crear un sinfín de
              recetas para poner tu creatividad al máximo nivel o si lo tuyo son
              los planes más sencillos, simplemente puedes servir y listo. Si
              estás en busca de ideas, aquí te dejamos algunas:
            </p>
          </div>
          <div>
            <ul className="list-inside list-disc marker:text-deliciousapple">
              <li>
                Sabores salados: sabores cítricos como Boone’s Pink Lemonade,
                Boone’s Tropical y Boone’s Sangría serán el complemento ideal.
              </li>
              <li>
                Sabores suaves: sabores como Boone’s Exotic Berry, Boone’s
                Strawberry Hill y Boone’s Cool Raspberry.
              </li>
              <li>
                Sabores dulces: puedes incluir sabores como Boone’s Sun Peak
                Peach y Boone’s Delicious Apple.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-10 grid grid-flow-col grid-cols-3 justify-center gap-2 rounded md:grid-cols-5">
          <StaticImage
            src="../assets/images/sabores/queesboones/img-video-1.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-video-2.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-video-3.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-video-4.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <StaticImage
            src="../assets/images/sabores/queesboones/img-video-5.png"
            alt="como se toma boones"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </div>
      </main>
    </Layout>
  );
}

export default acercade;

/*
          
          Cómo se PREPARA

            <Container>
              <div>
                <h5> COMO SE </h5>
                <h1 className="deliciousApple"> PREPARA </h1>
              </div>
              <Row>
                <Col lg={true} className="flexCenter">
                  <p>
                    ¿Antojo de algo saladito, suave, dulce o frutal? Si estás en
                    busca de acompañar o resaltar los sabores de tus platillos
                    favoritos, esta mini guía te ayudará a encontrar el match
                    perfecto para ese postre o comida favorita.
                  </p>
                </Col>
                <Col lg={true}>
                  <List>
                    <ListItem>
                      <ListItemIcon className="deliciousApple iconos">
                        <GiPeach />
                      </ListItemIcon>
                      <ListItemText primary="Sabores salados: sabores cítricos como Boone’s Pink Lemonade, Boone’s Tropical y Boone’s Sangría serán el complemento ideal." />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemIcon className="deliciousApple iconos">
                        <GiPeach />
                      </ListItemIcon>
                      <ListItemText primary="Sabores suaves: sabores como Boone’s Exotic Berry, Boone’s Strawberry Hill y Boone’s Cool Raspberry." />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemIcon className="deliciousApple iconos">
                        <GiPeach />
                      </ListItemIcon>
                      <ListItemText primary="Sabores dulces: puedes incluir sabores como Boone’s Sun Peak Peach y  Boone’s Delicious Apple." />
                    </ListItem>
                  </List>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row xs={3} sm={3} md={5} className="rowFotos">
                <Col>
                  <StaticImage
                    src="../assets/images/sabores/queesboones/img-video-1.png"
                    alt="como se toma boones"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../assets/images/sabores/queesboones/img-video-2.png"
                    alt="como se toma boones"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../assets/images/sabores/queesboones/img-video-3.png"
                    alt="como se toma boones"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../assets/images/sabores/queesboones/img-video-4.png"
                    alt="como se toma boones"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../assets/images/sabores/queesboones/img-video-5.png"
                    alt="como se toma boones"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                  />
                </Col>
              </Row>
            </Container>
            
            ::::::ABOUT PAGE::::::
            import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import BlocksRenderer from "../components/blocks-renderer";
import Headings from "../components/headings";

const AboutPage = () => {
  const { strapiAbout } = useStaticQuery(graphql`
    query {
      strapiAbout {
        title
        blocks {
          ...Blocks
        }
      }
    }
  `);
  const { title, blocks } = strapiAbout;

  const seo = {
    metaTitle: title,
    metaDescription: title,
  };

  return (
    <Layout>
      <Seo seo={seo} />
      <Headings title={strapiAbout.title} />
      <BlocksRenderer blocks={blocks} />
    </Layout>
  );
};
*/
